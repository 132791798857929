import React from 'react';
import {FiSend, FiEdit, FiFileText} from 'react-icons/fi'
import {IoMdClose, IoMdMore} from 'react-icons/io'
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md'
import {FaArrowLeft, FaArrowRight, FaLink} from 'react-icons/fa'
import {GiHamburgerMenu} from 'react-icons/gi'
import {AiOutlineGoogle, AiOutlineCode} from 'react-icons/ai';
import {TiImage} from 'react-icons/ti'
import styled from 'styled-components';



export const IconSpan = styled.span`
    font-size: 30px;
`

export const containerWidth = "50";

export const IconContainer = styled.div`
    width: ${containerWidth}px;
    height: 40px;
    font-size: 30px;
    text-align: center;
    padding-top: 10px;
`


export const SendIcon = FiSend;
export const CloseIcon = IoMdClose;
export const NextIcon = MdNavigateNext;
export const PrevIcon = MdNavigateBefore;
export const EditIcon = FiEdit;
export const MoreIcon = IoMdMore;
export const LeftIcon = FaArrowLeft;
export const RightIcon = FaArrowRight;
export const MenuIcon = GiHamburgerMenu;
export const Google = AiOutlineGoogle;
export const JSIcon = AiOutlineCode;
export const ImageIcon = TiImage;
export const TextIcon = FiFileText;
export const LinkIcon = FaLink;