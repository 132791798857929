import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux'
import {Row, Col, Title, Container, Line, OnlyLarge, OnlySmall} from '../theme'
import KbiList, {MODES as LIST_MODES} from '../components/KbiList'
import BlockContent from '@sanity/block-content-to-react'

const  Home = ()=>{
    
    const [client, intro] = useSelector(state=>[state.data.client, state.data.intro]);
    

    if (!client){
        return null;
    } else {
        return (
            <React.Fragment>
                <Row center="xs">
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <br/><br/><br/>
                        <BlockContent blocks={intro} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

  

export default Home;