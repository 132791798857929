import React, {useEffect, useRef, useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import {Grid, Row, Col, Container, Wrapper, InlineText, Title, Line} from '../theme'
import BlockContent from '@sanity/block-content-to-react'
import KbiList, {MODES as LISTMODES} from '../components/KbiList';
import Button from '../components/Button'
import scrollToRef from '../services/scrollto'


const Section = ({data})=>{
    
    const [client] = useSelector(state=>[state.data.client])
    const [scrolled, setScrolled] = useState(false);
    const myRef = useRef(null)

    useEffect(() => {
        if (data.scrollTo && !scrolled && myRef){
            setScrolled(true);
            scrollToRef(myRef);
        }
    });

    if (!client){
        return null;
    }


    return <React.Fragment>
        <Title ref={myRef}>{`${data.chapterindex}.${data.index+1} ${data.title}`}</Title>
        <InlineText>
            <br/>
            {data.content?<BlockContent blocks={data.content} />:null}
            <br/>
        </InlineText>
        {data.resources?<KbiList list={data.resources} type={LISTMODES.LIST} />:null} 
        <br/>
        <br/>
    </React.Fragment>
}

const Chapter = ({match})=>{
    const [section, setSection] = useState(null);
    const currentContent = useSelector(state=>state.data.currentContent);
    const book = useSelector(state=>state.data.book);
    const dispatch = useDispatch();

    useEffect(() => {
        if ( match.params && match.params.sectionslug && section!==match.params.sectionslug){
            setSection(match.params.sectionslug)
            dispatch({type: DATA_ACTIONS.SET_CURRENT_CONTENT, currentContent: null});
        } else if (book && book.chapters && match.params && match.params.chapterslug){
            let found = book.chapters.find((chapter)=>{
                return chapter.slug.current == match.params.chapterslug;
            })
            if (found && (!currentContent || currentContent._id !== found._id )){
                dispatch({type: DATA_ACTIONS.SET_CURRENT_CONTENT, currentContent: found});
            }
        }
    }, );

    if (currentContent){
        return <Grid style={{padding: 0, margin: 0, width: "100%"}}>
                <Container>
                    <br/>
                    <Row >
                        <Col xs={12} sm={11} md={10} mdOffset={1} lg={7} lgOffset={2}>
                            {currentContent.sections.map((section, index)=><Section key={section.title+index} data={{...section, chapterindex: currentContent.index, index, scrollTo: match.params.sectionslug == section.slug.current}} />)}
                        </Col>
                    </Row>
                </Container>
        </Grid>
    } else {
        return null;
    }

    
}

export default Chapter;