
import {medias} from "../theme/config.json";

export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_NAV_OPEN: Symbol('data/navopen'),
    SET_BOOK: Symbol('data/book'),
    SET_CURRENT_CONTENT: Symbol('data/currentcontent'),
    SET_INTRO: Symbol('data/intro')
});

const initialState = {
  client: null,
  navopen: window.innerWidth > medias.smp,
  book: null,
  currentContent: null,
  intro: null
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_NAV_OPEN: {
      return {
        ...state,
        navopen: action.navopen
      };
    }
    case ACTIONS.SET_BOOK: {
      return {
        ...state,
        book: action.book
      }
    }
    case ACTIONS.SET_CURRENT_CONTENT: {
      return {
        ...state,
        currentContent: action.currentContent
      }
    }
    case ACTIONS.SET_INTRO: {
      return {
        ...state,
        intro: action.intro
      }
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
