import styled, {css} from 'styled-components';
import "../assets/circular-std";
import {colors, fonts, margins, settings, medias} from "./config.json";
import {Grid as Grid_, Col as Col_, Row as Row_} from 'react-styled-flexboxgrid';


//MAIN
// -- Grid

export const Gridder = Grid_;
export const Coller = styled(Col_)`
  margin-bottom: ${props => props.marginBottom ? margins.div.general : null};
`;
export const Rower = Row_;
export const Grid = Grid_;
export const Row = Row_;
export const Col = Col_;

export const Section = styled.div``


export const OnlySmall = styled.div`
  @media ${medias.onlySmall} {
    display: inline;
  }
  display: none;
`
export const OnlyLarge = styled.div`
  @media ${medias.onlySmall} {
    display: none;
  }
  display: inline;
`

export const MediumMax = styled.div`
  @media ${medias.mdandup} {
    display: inline;
  }
  display: none;
`
export const MediumMin = styled.div`
  @media ${medias.mdandup} {
    display: none;
  }
  display: inline;
`

export const Smallbreak = styled.br`
  display: block;
  margin: 10px 0;
  content: " ";
`;

// -- Typo
export const Title = styled.h1`
  color: ${props => props.color ||  colors.textPrimary};
  font-size: ${fonts.size.title};
  text-align: ${props => props.align ||  fonts.align.title};
  font-weight: ${fonts.weight.title};
  text-transform: ${fonts.transform.title};
`;

export const SubTitle = styled.h2`
  font-size: 1.2em;
  text-align: center;
  color: ${props => props.color ||  'black'};
`;

export const InlineText = styled.span`
  color: ${props => props.color};
  font-size: ${fonts.size.book};
  text-align: ${props => props.align};
  font-weight: ${fonts.weight.book};
`

export const Text = styled.div`
  color: ${props => props.color ||  colors.textPrimary};
  font-size: ${fonts.size.book};
  text-align: ${props => props.align ||  fonts.align.book};
  font-weight: ${fonts.weight.book};
  
  padding-left: ${margins.text.left};
  padding-right: ${margins.text.right};
  padding-top: ${margins.text.top};
  padding-bottom: ${margins.text.top};
`;


// -- Graphics

export const Image = styled.img`
`;

export const ResponsiveImage = styled(Image)`
  width: 100% !important;
  transition: all .2s ease-in-out;
  height: ${props => props.height || null};
  object-fit: contain;
`;

export const ResponsiveImageWithEffect = styled(ResponsiveImage)`
&:hover {
  transform: scale(.9);
}
`;

//ImageTile
export const SquareImage = styled.div`
  background: url(${props => props.img}) no-repeat center center;
  background-size: cover;
  border-bottom: ${props => props.borderBottom ? "2px solid black" : "none"};
  display: inline-block;
  width: ${props => props.width ||  '50%'};
  padding-bottom: ${props => props.width ||  '50%'};
  transition: all .2s ease-in-out;
`

export const SquareImage2 = styled.td`
  height: ${props => props.width};
  width: ${props => props.width};
  background-image: url(${props => props.image})
  background-size: 100% auto;
  //background-size: cover;
  // background-repeat: no-repeat;
  // background-size: 50px 50px;
`

export const SquareImageWithEffect = styled(SquareImage)`
&:hover {
  transform: scale(.9);
}
`;

export const CircleImage = styled(Image)`
  width: 100% !important;
  border-radius: 50%;
  transition: all .2s ease-in-out;
  object-fit: cover;
`;

export const CircleImageWithEffect = styled(CircleImage)`
&:hover {
  transform: scale(.9);
}
`;

export const Line = styled.hr`
  border-top: 3px solid black;
  margin: 0px;
  padding: 0px;
`;

// -- Containers
export const defaultWrap = "solid black 3px";

export const Wrapper =  styled.div`
  border-left: ${props => props.noLeft? "0px" : defaultWrap || defaultWrap};
  border-right: ${props => props.noRight? "0px" : defaultWrap || defaultWrap};
  border-top: ${props => props.noTop? "0px" : defaultWrap || defaultWrap};
  border-bottom: ${props => props.noBottom? "0px" : defaultWrap || defaultWrap};
`

export const MainContainer = styled(Grid)`
  margin-top: 60px;
`;

export const LogoContainer = styled.div`
  width: 100% !important;
`;

export const Container = styled.div`
  margin: ${props => props.size? props.size : "30px"};
  margin-bottom: ${props=>props.noBottom?"0px":null};
  @media ${medias.onlySmall} {
   margin: 10px;
  }
`;

export const ContainerSides = styled(Container)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 38px;
  padding-right: 38px;
  @media ${medias.onlySmall} {
    padding-left: 10px;
    padding-right: 10px;
   }
`;

export const ContainerPadding = styled.div`
  padding: ${props => props.size? props.size : "30px"};
  padding-bottom: ${props => props.noBottom? "0px" : props.size? props.size : "30px"};
  @media ${medias.onlySmall} {
    padding: 10px;
   }
`;


export const Label = styled.label`
border: ${settings.borderline}px solid ${colors.textPrimary};
margin: 10px;
padding: 10px;
display:block
`

export const Panel = styled.div`
  border: ${settings.borderline}px solid ${colors.textPrimary};
  border-bottom: ${props => props.noBorderBottom ? 'none' : `${settings.borderline}px solid ${colors.textPrimary}`}
  margin: 0em;
  margin-top: ${margins.text.top};
  background-color: ${props=>props.backgroundColor || "transparant"};
`;

export const PanelTitle = styled.h3`
  border-bottom: ${props => props.noBorderBottom ? 'none' : '2px solid black'}
  font-size: 1em;
  text-align: left;
  color: ${props => props.color ||  'black'};
  margin: 0em;
  padding: 0.2em 0.5em;
  background-color: ${props=>props.backgroundColor || "transparant"};
`;






