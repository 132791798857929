import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import toast from '../services/toast'
import imageUrlBuilder from '@sanity/image-url'
import {nanoid} from 'nanoid'
import { MdAssignment } from 'react-icons/md'

const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const getData = ()=>{
        return new Promise((resolve, reject)=>{
            sc.fetch('*[_type == "siteSettings"][0]{intro,"chapters": presentation.chapters[]->{title, _id, slug, sections[]{title, _type, content, slug, resources[]{"url" : image.asset->url,title, description, _type}}}}')
            .then(({intro, chapters})=>{

                if (chapters){
                    chapters.forEach((chapter, chapterindex) => {
                        if (chapter && chapter.sections){
                            chapter.sections.forEach((section) =>{
                                chapter.index = chapterindex+1;
                            })
                        }
                    });
                }

                console.log(intro)

                dispatch({type: DATA_ACTIONS.SET_BOOK, book: {chapters}});
                dispatch({type: DATA_ACTIONS.SET_INTRO, intro});
                resolve({intro, chapters});
            }).catch(e=>reject(e));
        })
    }

    (()=>{
        getData();
        
        if (environment.dev){
            environment.printstatus()
        } 
    })()

    

    return {
        fetch,
        environment,
        squareImage,
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

