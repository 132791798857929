import React, {useEffect} from 'react';
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Store from "../Store";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sanityclient from '../services/client';
import { css } from 'glamor';
import '../style.css';
import Nav from './Nav'
import Home from '../pages/home'
import Chapter from '../pages/chapter'
import {Grid, Row, Col, Container} from '../theme'


/*

Feedback Vicky
  - ✔ logout
  - ✔ errorcode in het rood
  - ✔ sketchmaker boven Profile 
  - UI persistent maken ; open van sketchmaker / avatar
  - ✔ apart scrolling van linker en rechterkant
  - ✔ links ook open geraken
  - ✔ description link boven button
  - ✔  by duplicate code ; - saved to my sketches
  - ✔  op profiel pagina; my sketches
  - ✔ Zichtbaar "missed assignment" / "finished" / "due"

*/

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      toast.configure()
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 
  })
  return (
    null
  )
}

const Toaster = ()=>{
  const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
    <i onClick={closeToast}></i>
  );
  return <ToastContainer
    toastClassName={css({color:"black", border: "solid black 2px"})}
    closeButton={<CloseButton/>}
  />
}

const AutoLogin = ()=>{
    return null;
}

const history = createBrowserHistory()

const App =()=>{

  return (
    <Provider store={Store}>
    <DataHelper />
    <Toaster />
      <Router history={history}>
        <span>
          <Nav>
            <Grid style={{margin: "0px", padding: "0px", width: "100%"}}>
            <AutoLogin />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/:chapterslug" component={Chapter} />
                <Route exact path="/:chapterslug/:sectionslug" component={Chapter} />
                <Route component={Home}/>
              </Switch>
            </Grid>
          </Nav>
        </span>
      </Router>
  </Provider>
  );
}

export default App;
