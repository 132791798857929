import React, {useState, useRef, useEffect} from 'react'
import {Col, Section, Line, Title, Wrapper, Container, defaultWrap, ResponsiveImage} from  '../theme'
import {medias} from "../theme/config.json";
import {useSelector} from 'react-redux'
import Collapsible from './CollapsibleContainer'
import BlockContent from '@sanity/block-content-to-react'
import styled from 'styled-components';
import './prism.css';
import PrismCode from 'react-prism';
import Button from './Button';
import { TextIcon, ImageIcon, LinkIcon, IconContainer} from '../theme/icons';
import Expand from 'react-expand-animated';

export const MODES = Object.freeze({
    LIST: Symbol("list"),
    PANEL_LIST: Symbol("panel")
});

const ListIconContainer = styled.td`
    width: 40px;
    height: 40px;
    background: black;
    color: white;
    border: ${defaultWrap};
`

const ListContentContainer = styled.td`
    border-bottom: ${defaultWrap};
    padding-left: 10px;
`

const CodeContainer = styled.div`
    // max-width: 600px;
    // background: red;
    // background-color: red;
    
    @media ${medias.onlySmall} {
        max-width: ${props => props.open?"200px":"400px"};
    }

    max-width: ${props => props.open?"400px":"500px"};
`

const ImageViewContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: red;
`

const ListIconContainer2 = ({children})=>(
    <ListIconContainer>
        <IconContainer>
            {children}
        </IconContainer>
    </ListIconContainer>
)

const ImageView = ({src, title, description})=>(
    <ImageViewContainer>
        i am imageview
    </ImageViewContainer>
)


const ImageCard = ({src})=>(
    <ResponsiveImage src={src} />
)

const TextCard = ({description})=>(
    <Section>
        <BlockContent blocks={description} />
    </Section>
)

const LinkCard = ({description, url})=>(
    <Section>
        <BlockContent blocks={description} />
        <Button text="open link" link={url} options={{external:true}}/>
    </Section>
)

const ListRow = ({icon, title, content, beginOpen, description})=>{
    const [open, setOpen] = useState(beginOpen || false);
    const [transit, setTransit] = useState(false);
    const duration = 400;
    
    const toggle = ()=>{
        setTimeout(() => {
            setOpen(!open);
        }, 10);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
    }

    return <div>
        
        {content}    
        
        
        <div style={{marginTop: "0px", marginLeft: "30px", marginBottom: "50px"}}>
            <b>{title}</b>
            <i>
                {description?<BlockContent blocks={description}/>:null}
            </i>
        </div>
    </div>
}


const KbiList = ({list, type, targetWidth})=>{
    const member = useSelector(state=>state.data.member);
    const client = useSelector(state=>state.data.client);

    if (!client){
        return null;
    }

    switch (type) {
        case MODES.LIST:
            return <table cellSpacing="0" style={{width: "100%", borderBottom: "0px"}}>
                <tbody >
                {list.map((item, index)=>{
                let [icon, content] = [null, null];                

                switch (item._type) {
                    case "image_kbi":
                        icon    = <ImageIcon />
                        content = <ImageCard src={item.url}  />
                        break;
                    case "link_kbi":
                        icon    = <LinkIcon />
                        content = <LinkCard url={item.url} description={item.description} />
                        break;
                    case "text_kbi":
                        icon    = <TextIcon />
                        content = <TextCard description={item.description} />
                        break;
                    default:
                }

                return <ListRow title={item.title} icon={icon} content={content} beginOpen={true} key={index+item.title} description={item.description}/>
                })}
                </tbody>
            </table>
        default:
            return null;
    }

}

export default KbiList;