import React, { Component, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Wrapper, defaultWrap, Container, OnlySmall, OnlyLarge, Section} from '../theme';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {MenuIcon, IconContainer, IconSpan} from '../theme/icons';
import { useLocation } from 'react-router-dom';
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

export const navHeight = 50;
export const navPanelWidth = 300;
export const navPanelWidthSmall = 50;

const NavTitle = styled.span`
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
`

const NavBalk = styled(Wrapper)`
    position: fixed;
    height: ${navHeight}px;
    background: white;
    left: 0px;
    right: 0px;
    top: 0px;
    line-height: ${navHeight-3}px;
    text-align: center;
    z-index: 10;
`

const LeftContainer = styled.div`
    width:  ${props => props.open? navPanelWidth : navPanelWidthSmall || navPanelWidth}px;
    height: ${navHeight}px;
    border-right: ${defaultWrap};
    float: left;
    transition: width .2s ease-out;
`
const LeftContainerFull = styled.div`
    width:  ${props => props.open? navPanelWidth : "0" || navPanelWidth}px;
    transition: transform .2s ease-out;
    // min-height: calc(100vh - ${navHeight+9}px);
    height: calc(100vh - ${navHeight+9}px);
    border: ${defaultWrap};
    border-top: 0px;
    float: left;
    position: fixed;
    top: ${navHeight+6}px;
    transform: translateX(${props => props.open? "0" : -navPanelWidth || "0"}px);
    overflow-y: scroll;
`

const RighterHelper = styled.div`
    margin-left: ${props => props.open? navPanelWidth : "0" || navPanelWidth}px;
    transition: margin-left .2s ease-out;
`

const ContentContainer = styled(RighterHelper)`
    margin-top: ${navHeight+5}px;
    transform: translateX(3px);
`

const RightContainer = styled(RighterHelper)`
    height: ${navHeight}px;
`

export const MenuItem = styled.div`
    margin-bottom: 8px;
    padding-left: ${props=>props.indent?"20":"0"||"0"}px;
    text-decoration: ${props=>props.finished?"line-through":null};
    &:hover {
        font-style: italic;
        text-decoration: none;
    }
`

const Nav = ({children})=>{
    //const [open, setOpen] = useState(window.innerWidth > medias.smp);
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const open = useSelector(state=>state.data.navopen);
    const book = useSelector(state=>state.data.book);
    const client = useSelector(state=>state.data.client);
    const location = useLocation();
    const currentContent = useSelector(state=>state.data.currentContent);

    useEffect(() => {
        const currentPath = location.pathname;
    
        let newTitle = "";

        if (currentPath == "/"){
            newTitle = "home";
        } else if (currentContent) {
            newTitle = `${currentContent.index} ${currentContent.title}`
        }

        if (title!=newTitle){
            setTitle(newTitle)
        }

    });

    const toggle = ()=>{
        dispatch({type: DATA_ACTIONS.SET_NAV_OPEN, navopen: !open})
    }

    const getMenuContent = ()=>{
        
        const MenuRow = ({link, text, indent, finished})=>(
            <Link to={link}>
                <MenuItem indent={indent} finished={finished}>
                    {text}
                </MenuItem>
            </Link>
        )
        return(
        <Container>
            <div >
                <MenuRow link='/' text="Intro" />
                {(book && book.chapters && book.chapters.length)? book.chapters.map((chapter, index)=>{
                    return <React.Fragment key={chapter.slug.current+index}> 
                        <MenuRow link={`/${chapter.slug.current}`} text={`${index+1} ${chapter.title}`} />
                        {chapter.sections?chapter.sections.map((section, sectionindex)=>{
                            return <MenuRow key={chapter.slug.current+sectionindex} link={`/${chapter.slug.current}/${section.slug.current}`} text={`${index+1}.${sectionindex+1} ${section.title}`} finished={section.finished} indent={true}/>
                        }) : null}
                    </React.Fragment>
                }) : null}
                <br/>
                
                <a href='https://tgtstudio.netlify.app/' target="_blank" > CMS </a> <br/><br/>
                <img src="https://api.netlify.com/api/v1/badges/66849ded-b0ac-48e8-8da0-344b1d6a601f/deploy-status"/>
            </div>
        </Container>
    )}

    return (
        <React.Fragment>
            <OnlySmall>
                <NavBalk onClick={toggle} open={open} style={{textAlign: "left"}}>
                        <NavTitle >
                        <IconContainer style={{ float: "left", paddingLeft: "10px", transform: 'translate(0, -3px)'}} noTop noBottom noLeft noRight>
                            <MenuIcon/>
                        </IconContainer>
                            <div style={{textAlign: "center", width: `calc(100% - ${60}px)`, float: "right",  transform: 'translate(-30px)'}}>
                            TGT
                            </div>
                        </NavTitle>
                </NavBalk>
            </OnlySmall>
            <OnlyLarge>
                <NavBalk >
                    <LeftContainer onClick={toggle} open={open}>
                        {open?<NavTitle>
                            Index
                        </NavTitle>:<IconContainer noTop noBottom noLeft noRight style={{transform: 'translate(0, -3px)'}}>
                            <MenuIcon/>
                        </IconContainer>}
                    </LeftContainer>
                    <RightContainer open={open}>
                        <NavTitle>
                            <OnlyLarge>
                                The Great Transformation /&nbsp;
                            </OnlyLarge>
                            {title}
                        </NavTitle>
                    </RightContainer>
                </NavBalk>
            </OnlyLarge>
            <LeftContainerFull open={open}>
                        {getMenuContent()}
                </LeftContainerFull>
                <ContentContainer open={open}>
                    {children}
                    <br/><br/><br/><br/><br/>
                    {client?<div style={{textAlign: "center", margin: "20px"}}>
                    Copyright © 2020 The Great Transformation - <a target="_blank" href="http://www.architectureworkroom.eu/en/">Architecture Workroom Brussels</a> -  Made with ♥ by Studio Subtiv
                    </div>:null}
                    
                    <br/><br/>
                </ContentContainer>
        </React.Fragment>
    )
}



export default Nav;